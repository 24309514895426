import React, { Component } from 'react'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import ReadNext from '@components/read-next-button/read-next-button'
import LearnMoreComponent from '@components/learn-more/learn-more.component'

import psychiatricRates from '@assets/images/infographics/chart-comorbid-psychiatric-rates.svg'
import sleepDisorderRates from '@assets/images/infographics/chart-comorbid-sleep-disorder-ratesv2.svg'

import './comorbidities-sleep.scss'

class SleepComorbidities extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const readNext = {
      readNextContent: 'Learn more about narcolepsy management',
      goTo: '/disease-management/treatment-decisions/',
      tracking: 'psych and sleep comorbidities,	click, modifiable risks - psychsleep',
    }

    const learnMoreContent = {
      className: 'learn-more-wrapper-gray',
      title: 'Patients with narcolepsy also have increased rates of CV and cardiometabolic comorbidities',
      supText: '1,2',
      linkLabel: 'See the data',
      link: '/comorbidities-risk/cardiovascular-comorbidities/',
      tracking: 'psych and sleep comorbidities, click, cv comorbidities - psychsleep'
    }

    const references = [
      {
        content:
          'Ohayon MM. Narcolepsy is complicated by high medical and psychiatric comorbidities: a comparison with the general population. ',
        source: 'Sleep Med.',
        info: '2013;14(6):488-492.',
      },
      {
        content:
          'Black J, Reaven NL, Funk SE, et al. Medical comorbidity in narcolepsy: findings from the Burden of Narcolepsy Disease (BOND) study. ',
        source: 'Sleep Med. ',
        info: '2017;33:13-18.',
      },
      {
        content:
          'Jennum P, Ibsen R, Knudsen S, Kjellberg J. Comorbidity and mortality of narcolepsy: a controlled retro- and prospective national study. ',
        source: 'Sleep. ',
        info: '2013;36(6):835-840.',
      },
      {
        content:
          'Aran A, Einen M, Lin L, Plazzi G, Nishino S, Mignot E. Clinical and therapeutic aspects of childhood narcolepsy-cataplexy: a retrospective study of 51 children. ',
        source: 'Sleep. ',
        info: '2010;33(11):1457-1464.',
      },
      {
        content:
          'Guilleminault C, Pelayo R. Narcolepsy in prepubertal children. ',
        source: 'Ann Neurol. ',
        info: '1998;43(1):135-142. ',
      },
      {
        content:
          'Sansa G, Iranzo A, Santamaria J. Obstructive sleep apnea in narcolepsy. ',
        source: 'Sleep Med. ',
        info: '2010;11(1):93-95.',
      },
      {
        content:
          'Nightingale S, Orgill JC, Ebrahim IO, de Lacy SF, Agrawal S, Williams AJ. The association between narcolepsy and REM behavior disorder (RBD). ',
        source: 'Sleep Med. ',
        info: '2005;6(3):253-258.',
      },
      {
        content:
          'Pereira D, Lopes E, da Silva Behrens NS, et al. Prevalence of periodical leg movements in patients with narcolepsy in an outpatient facility in São Paulo. ',
        source: 'Sleep Sci. ',
        info: '2014;7(1):69-71.',
      },
      {
        content:
          'Thorpy MJ, Krieger AC. Delayed diagnosis of narcolepsy: characterization and impact. ',
        source: 'Sleep Med. ',
        info: '2014;15(5):502-507.',
      },
    ];

    return (
      <Layout addedClass='page-comorbidities-sleep' location={this.props.location}>
        <div className="comorbidities-page-header " role="img" alt="Cloudy open terrain path with boulders">
          <div className="primary-container">
            <div className="comorbidities-header-text">
              <h1>Psychiatric and Sleep Disorders Are Frequently Observed in Patients With Narcolepsy<sup>1-3</sup></h1>
              <h3>Narcolepsy is associated with a psychological impact, often beginning in adolescence, and other sleep disorders are common in patients with narcolepsy.<sup>3-8</sup></h3>
            </div>
          </div>
        </div>
        <div className="background-white">
          <section className="section-gradient" />
        </div>
        <div className="primary-container">
          <div className="info-section-title">
            <h2>Psychiatric and Sleep Comorbidities</h2>
            <div className="content-wrapper-header">
              <LearnMoreComponent learnMoreContent={learnMoreContent} />
            </div>
          </div>
          <div className="psych-info-container-wrapper">
            <div className="graph-info-section">
              <div className="info-section">
                <h3 className="info-section-header">
                  Patients with narcolepsy have higher rates of comorbid psychiatric and sleep disorders, which can further delay diagnosis of narcolepsy<sup>1,6-9</sup>
                </h3>
                <p className="info-section-content">
                  Narcolepsy is associated with a higher rate of psychiatric conditions. In a survey that aimed to understand the psychiatric and medical conditions associated with narcolepsy, compared with the general population, patients with narcolepsy were more likely to have certain psychiatric disorders, including major depressive disorder, bipolar disorders, and anxiety disorders.<sup>1</sup>
                </p>
              </div>
              <div className="physciatric-rates-img-text-wrapper">
                <div className="chart-container">
                  <h4 className="chart-title">Reported rates of comorbid psychiatric conditions<sup>1</sup></h4>
                  <img
                    src={psychiatricRates}
                    className="physciatric-rates-img chart-image"
                    alt="bar graph reporting rates of 6 psychiatric conditions"
                  />
                  <p className="chart-caption">MDD, major depressive disorder; PTSD, posttraumatic stress disorder.</p>
                </div>
              </div>
            </div>
            <div className="info-section-graphs">
              <div className="sleep-disorders-img">
                <div className="chart-container">
                  <h4 className="chart-title rates-title">Reported rates of comorbid sleep <span className="no-wrap">disorders<sup>6-8</sup></span></h4>
                  <img 
                    className="chart-image"
                    src={sleepDisorderRates} 
                    alt="Chart with 4 rows reporting rates of comorbid sleep disorders" 
                  />
                </div>
                <p className="minor-comment">
                  OSA, obstructive sleep apnea; PLMS, periodic limb movements of sleep; RBD, REM sleep behavior disorder.
                </p>
              </div>
              <div className="info-section">
                <p className="info-section-content second-content-styling">
                In addition to psychiatric conditions, other sleep disorders are also common in patients with narcolepsy, such as obstructive sleep apnea, periodic limb movements of sleep, and REM sleep behavior disorder.<sup>6-8</sup>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="primary-container">
          <ReadNext readNext={readNext} />
        </div>
        <ReferenceComponent referenceList={references} />
      </Layout>
    )
  }
}

export default SleepComorbidities
